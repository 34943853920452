import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const Datenschutz = ({data}) => {
  return (
    <Layout title="Datenschutzerklärung">
      <h1>Datenschutzerklärung</h1>
      <p>Unsere Webseite speichert grundsätzlich keine personenbezogenen Informationen. Bei Fragen, können Sie gerne <Link to="/kontakt/">Kontakt</Link> mit uns aufnehmen.</p>
      <h4>Online Anmeldeformular</h4>
      <p> Informationen, die Sie uns über das Online Anmeldeformular zusenden werden der Heimleitung per E-Mail zugestellt und vertraulich behandelt. Ihre Daten werden in nicht in einer Datenbank gespeichert und nicht mit Drittanbietern geteilt.</p>
      <h4>AWStats</h4>
      <p>Zur statistischen Auswertung der Webseite kommt der Open Source Log File Analyzer AWStats zum Einsatz. Dieser wertet die Logdateien des Webservers aus. Zwar sind diese Daten in der Regel nicht bestimmten Personen zuordenbar, dennoch enthalten die Logdateien Hostnames und IP-Adressen der Besucheranfragen. Diese Daten werden nicht an Dritte übermittelt und nicht mit anderen Datenquellen zusammengeführt sowie nach der statistischen Auswertung gelöscht. Die Logdateien befinden sich auf Servern in der Schweiz, wodurch eine serverseitige Übermittlung der Daten ins Ausland vermieden wird.</p>
    </Layout>
  ) 
}

export default Datenschutz